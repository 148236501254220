<template>
  <v-layout justify-center align-center row wrap>
    <v-flex xs12 md5 :px-4="$vuetify.breakpoint.smAndDown">
      <p class="headline font-weight-bold mb-1">Finding Lawyers, <span class="primary--text">Reimagined</span></p>
      <p class="headline font-weight-light">A lawyer equipped to provide the best legal advice is just at the tip of your fingertips. LawyerKo enables you to connect with a lawyer of your choice wherever you are, whatever you are doing.</p>
    </v-flex>
    <v-spacer></v-spacer>
    <v-flex class="hidden-sm-and-down" xs6 my-5>
      <v-img
        src="https://res.cloudinary.com/starupgeek-law/image/upload/v1577956596/c8zj5jwanz34me6kvdzz.png"
      ></v-img>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style>

</style>
