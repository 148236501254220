<template>
  <v-row align="center" justify="center" style="margin: 100px 0px 100px 0px;">
    <v-flex xs12><h2 class="text-center">How It Works</h2></v-flex>
    <v-flex xs10>
      <v-tabs :centered="$vuetify.breakpoint.mdAndUp" :fixed-tabs="$vuetify.breakpoint.smAndDown" background-color="transparent" color="primary">
        <v-tab href="#tab-1">
          For Clients
        </v-tab>
        <v-tab href="#tab-2">
          For Lawyers
        </v-tab>
        <v-tab-item
          v-for="i in 4"
          :key="i"
          :value="'tab-' + i"
          
        >
          <!-- For Client -->
          <div v-if="i == 1" style="background-color: #F0F2F5;" flat>
            <v-row align="center">
              <v-flex hidden-sm-and-down xs6 mt-3>
                <v-img
                  src="https://res.cloudinary.com/starupgeek-law/image/upload/v1578038426/vx1uskguvsp9a5hjimgq.png"
                ></v-img>
              </v-flex>
              <v-flex 
                xs12
                md6
                :class="{
                  'mt-5' : $vuetify.breakpoint.mdAndUp,
                  'px-4 pt-4' : $vuetify.breakpoint.smAndDown
                }">
                <v-chip class="subheading font-weight-bold" color="primary" text-color="white">1</v-chip>
                <span class="pl-2 title font-weight-bold">Post your legal queries</span>
                <p :class="{
                  'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp, 
                  'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                }">
                  Posting your questions and problems is always free.
                  Lawyers will then propose to answer them for a certain fee and offer to answer up to three follow-up questions related to your problem.
                </p>
                <p :class="{
                  'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp,
                  'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                }">
                  Lawyers can also opt to give you free legal advice.
                </p>
                  <v-chip class="subheading font-weight-bold" color="primary" text-color="white">2</v-chip>
                  <span class="pl-2 title font-weight-bold">Pick your lawyer</span>
                  <p :class="{
                    'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp,
                    'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                  }">
                    Choose the best lawyer you think is suited for you based on the proposal they submitted.
                    Once you selected a lawyer, both you and your lawyer will start discussing about your concern and fee agreement.
                    If follow-up questions are allowed, you may ask further questions and the lawyer would answer them.
                  </p>
                  <v-chip class="subheading font-weight-bold" color="primary" text-color="white">3</v-chip>
                  <span class="pl-2 title font-weight-bold">Complete and rate</span>
                  <p :class="{
                    'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp,
                    'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                  }">
                    After the lawyer finishes answering all of your queries and you are satisfied with the advice given,
                    you can complete the task so that the fee can be forwarded to the lawyer.
                  </p>
                  <p :class="{
                    'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp,
                    'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                  }">
                    If you are not satisfied with the answers in any way or feel that the laywer seems unresponsive or behaved unprofessionally,
                    you may opt to file a dispute and our team will review and resolve  the matter as soon as possible.
                  </p>
              </v-flex>
            </v-row>
          </div>
          <!-- For Lawyer -->
          <div v-if="i == 2" flat tile style="background-color: #F0F2F5;">
            <v-layout>
              <v-flex xs12 md6 :px-4="$vuetify.breakpoint.smAndDown" 
                :class="{'mt-5 px-4' : $vuetify.breakpoint.mdAndUp,
                       'px-4 pt-4' : $vuetify.breakpoint.smAndDown}"
              >
                <v-chip class="subheading font-weight-bold" color="primary" text-color="white">1</v-chip>
                <span class="pl-2 title font-weight-bold">Register and get verified</span>
                <p :class="{
                  'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp,
                  'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                }">
                  Complete your profile information and let us verify your details to be able to propose for projects.
                  Create a good impression to clients by having a detailed profile.
                </p>
                <div :style="{'margin-top: 60px;': $vuetify.breakpoint.mdAndUp}">
                  <v-chip class="subheading font-weight-bold" color="primary" text-color="white">2</v-chip>
                  <span class="pl-2 title font-weight-bold">Find task</span>
                  <p :class="{
                    'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp,
                    'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                  }">
                    Browse through clients’ legal questions or inquiries posted in the website.
                  </p>
                </div>
                <div :style="{'margin-top: 60px;': $vuetify.breakpoint.mdAndUp}">
                  <v-chip class="subheading font-weight-bold" color="primary" text-color="white">3</v-chip>
                  <span class="pl-2 title font-weight-bold">Select projects and bid</span>
                  <p :class="{
                    'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp,
                    'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                  }">
                    Pick projects which you think you can properly handle and make proposals by stating your
                    and giving the clients a brief description of yourself as well as your expertise in relation to the task. 
                  </p>
                </div>
                <div :style="{'margin-top: 60px;': $vuetify.breakpoint.mdAndUp}">
                  <v-chip class="subheading font-weight-bold" color="primary" text-color="white">4</v-chip>
                  <span class="pl-2 title font-weight-bold">Do the job and earn</span>
                  <p :class="{
                    'px-5 ml-5 justifyText' : $vuetify.breakpoint.mdAndUp,
                    'pl-9 ml-1 justifyText' : $vuetify.breakpoint.smAndDown
                  }">
                    Once chosen, you may complete the task by giving the needed legal advice or drafting the document.
                    After completing the task, the client will give a review on your work and you will earn your proposed fee.
                  </p>
                </div>
              </v-flex>
              <v-flex hidden-sm-and-down xs6 mt-3>
                <v-img
                  src="https://res.cloudinary.com/starupgeek-law/image/upload/v1578038427/irdm56krlosckxdeatft.png"
                ></v-img>
              </v-flex>
            </v-layout>
          </div>
        </v-tab-item>
      </v-tabs>
    </v-flex>
  </v-row>
</template>

<script>
export default {
}
</script>

<style scoped>
  .responsive {
    max-width: 100%;
    height: auto;
  }
  .padding-left-right {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .colorPrimary {
    color: #5F27CD;
  }
</style>
