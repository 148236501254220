<template>
  <v-row justify="center">
    <v-col cols="12" md="10" lg="10" xl="8">
      <Header />
      <Hero />
      <WhatWeOffer />
      <HowItWorks />
    </v-col>
  </v-row>
</template>

<script>
import Header from '../components/IndexComponents/Header'
import Hero from '../components/IndexComponents/Hero'
import HowItWorks from '../components/IndexComponents/HowItWorks'
import WhatWeOffer from '../components/IndexComponents/WhatWeOffer'

export default {
  name: 'IndexPage',
  components: {
    Header,
    Hero,
    HowItWorks,
    WhatWeOffer
  }
}
</script>
