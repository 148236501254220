<template>
  <div>
    <v-app-bar
      app
      flat
      class="py-2"
      color="white"
      style="box-shadow: 0 1px 3px 0 hsla(0, 0%, 0%, 0.1);"
      elevate-on-scroll
      height="60"
    >
      <v-row align="center" class="pl-2">
        <v-col cols="10" class="pa-0">
          <img
            src="https://res.cloudinary.com/starupgeek-law/image/upload/v1583571530/assets/txrdl3nqu81mbs7ykxyk.png"
            width="40%"
          />
        </v-col>
        <v-col cols="2" class="pl-2 pt-1">
          <v-avatar @click.stop="drawer = !drawer" size="40" color="bgGrey">
            <v-icon>mdi-menu</v-icon>
          </v-avatar>
        </v-col>
      </v-row>
    
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      clipped
      disable-resize-watcher
      app
    > 
      <v-flex xs12 class="text-center pb-2 pt-3">
        <img src="https://res.cloudinary.com/starupgeek-law/image/upload/v1583571530/assets/txrdl3nqu81mbs7ykxyk.png" width="60%">
      </v-flex>
      <v-list class="pt-0" dense>
        <v-divider></v-divider>
        <v-list-item to="/" color="primary">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
         <v-list-item to="/login" color="primary">
          <v-list-item-action>
            <v-icon>vpn_key</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/register" color="primary">
          <v-list-item-action>
            <v-icon>how_to_reg</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign up</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fab: false,
      drawer: null
    }
  }
}
</script>

<style>

</style>
