<template>
  <v-layout row wrap align-center :pt-5="$vuetify.breakpoint.smAndDown" :pt-12="!$vuetify.breakpoint.smAndDown">
    <v-flex class="hidden-sm-and-down" xs5>
      <v-img
        src="https://res.cloudinary.com/starupgeek-law/image/upload/v1577931698/kvrpuv9znhtvsaionzaf.png"
      ></v-img>
    </v-flex>
    <v-spacer></v-spacer>
    <v-flex xs12 md6 :px-4="$vuetify.breakpoint.smAndDown">
      <p class="display-1 font-weight-bold text-center">What We Offer</p>
      <p class="subheading font-weight-bold text-center">Need legal advice?<br />
      Find a lawyer online to answer your legal questions.
      </p>

      <p class="text-center headline font-weight-medium primary--text">For Clients</p>
      <p>
        Register for free at LawyerKo to get legal advice from real lawyers! At no cost, you can post legal questions or problems and hire lawyers to answer them. Whenever you post a task, lawyers will make proposals stating their legal fees and their personalized messages to help you decide which lawyer is fit for the job.
      </p>
      <p class="text-center headline font-weight-medium primary--text pt-4">For Lawyers</p>
      <p>
        Work smarter with LawyerKo! Register for free to connect with clients all over the country and talk to them online anytime, anywhere. Our platform also helps you promote yourself, build your profile, and acquire more experience in the law profession.
      </p>
      <p>
        By registering, you can offer your legal services to clients. Our platform can help you land on projects from clients who need legal advice. You may extend assistance by sending your proposals as to your required fees.
      </p>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style>

</style>
