<template>
  <IndexToolbar v-if="!$vuetify.breakpoint.smAndDown" />
  <NavDrawer v-else />
</template>

<script>
import NavDrawer from '@/components/NavigationComponents/NavDrawer'
import IndexToolbar from '@/components/NavigationComponents/IndexToolbar'

export default {
  components: {
    NavDrawer,
    IndexToolbar
  }
}
</script>
