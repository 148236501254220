var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"margin":"100px 0px 100px 0px"},attrs:{"align":"center","justify":"center"}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h2',{staticClass:"text-center"},[_vm._v("How It Works")])]),_c('v-flex',{attrs:{"xs10":""}},[_c('v-tabs',{attrs:{"centered":_vm.$vuetify.breakpoint.mdAndUp,"fixed-tabs":_vm.$vuetify.breakpoint.smAndDown,"background-color":"transparent","color":"primary"}},[_c('v-tab',{attrs:{"href":"#tab-1"}},[_vm._v(" For Clients ")]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_vm._v(" For Lawyers ")]),_vm._l((4),function(i){return _c('v-tab-item',{key:i,attrs:{"value":'tab-' + i}},[(i == 1)?_c('div',{staticStyle:{"background-color":"#F0F2F5"},attrs:{"flat":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-flex',{attrs:{"hidden-sm-and-down":"","xs6":"","mt-3":""}},[_c('v-img',{attrs:{"src":"https://res.cloudinary.com/starupgeek-law/image/upload/v1578038426/vx1uskguvsp9a5hjimgq.png"}})],1),_c('v-flex',{class:{
                'mt-5' : _vm.$vuetify.breakpoint.mdAndUp,
                'px-4 pt-4' : _vm.$vuetify.breakpoint.smAndDown
              },attrs:{"xs12":"","md6":""}},[_c('v-chip',{staticClass:"subheading font-weight-bold",attrs:{"color":"primary","text-color":"white"}},[_vm._v("1")]),_c('span',{staticClass:"pl-2 title font-weight-bold"},[_vm._v("Post your legal queries")]),_c('p',{class:{
                'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp, 
                'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
              }},[_vm._v(" Posting your questions and problems is always free. Lawyers will then propose to answer them for a certain fee and offer to answer up to three follow-up questions related to your problem. ")]),_c('p',{class:{
                'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp,
                'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
              }},[_vm._v(" Lawyers can also opt to give you free legal advice. ")]),_c('v-chip',{staticClass:"subheading font-weight-bold",attrs:{"color":"primary","text-color":"white"}},[_vm._v("2")]),_c('span',{staticClass:"pl-2 title font-weight-bold"},[_vm._v("Pick your lawyer")]),_c('p',{class:{
                  'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp,
                  'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v(" Choose the best lawyer you think is suited for you based on the proposal they submitted. Once you selected a lawyer, both you and your lawyer will start discussing about your concern and fee agreement. If follow-up questions are allowed, you may ask further questions and the lawyer would answer them. ")]),_c('v-chip',{staticClass:"subheading font-weight-bold",attrs:{"color":"primary","text-color":"white"}},[_vm._v("3")]),_c('span',{staticClass:"pl-2 title font-weight-bold"},[_vm._v("Complete and rate")]),_c('p',{class:{
                  'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp,
                  'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v(" After the lawyer finishes answering all of your queries and you are satisfied with the advice given, you can complete the task so that the fee can be forwarded to the lawyer. ")]),_c('p',{class:{
                  'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp,
                  'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v(" If you are not satisfied with the answers in any way or feel that the laywer seems unresponsive or behaved unprofessionally, you may opt to file a dispute and our team will review and resolve the matter as soon as possible. ")])],1)],1)],1):_vm._e(),(i == 2)?_c('div',{staticStyle:{"background-color":"#F0F2F5"},attrs:{"flat":"","tile":""}},[_c('v-layout',[_c('v-flex',{class:{'mt-5 px-4' : _vm.$vuetify.breakpoint.mdAndUp,
                     'px-4 pt-4' : _vm.$vuetify.breakpoint.smAndDown},attrs:{"xs12":"","md6":"","px-4":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-chip',{staticClass:"subheading font-weight-bold",attrs:{"color":"primary","text-color":"white"}},[_vm._v("1")]),_c('span',{staticClass:"pl-2 title font-weight-bold"},[_vm._v("Register and get verified")]),_c('p',{class:{
                'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp,
                'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
              }},[_vm._v(" Complete your profile information and let us verify your details to be able to propose for projects. Create a good impression to clients by having a detailed profile. ")]),_c('div',{style:({'margin-top: 60px;': _vm.$vuetify.breakpoint.mdAndUp})},[_c('v-chip',{staticClass:"subheading font-weight-bold",attrs:{"color":"primary","text-color":"white"}},[_vm._v("2")]),_c('span',{staticClass:"pl-2 title font-weight-bold"},[_vm._v("Find task")]),_c('p',{class:{
                  'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp,
                  'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v(" Browse through clients’ legal questions or inquiries posted in the website. ")])],1),_c('div',{style:({'margin-top: 60px;': _vm.$vuetify.breakpoint.mdAndUp})},[_c('v-chip',{staticClass:"subheading font-weight-bold",attrs:{"color":"primary","text-color":"white"}},[_vm._v("3")]),_c('span',{staticClass:"pl-2 title font-weight-bold"},[_vm._v("Select projects and bid")]),_c('p',{class:{
                  'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp,
                  'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v(" Pick projects which you think you can properly handle and make proposals by stating your and giving the clients a brief description of yourself as well as your expertise in relation to the task. ")])],1),_c('div',{style:({'margin-top: 60px;': _vm.$vuetify.breakpoint.mdAndUp})},[_c('v-chip',{staticClass:"subheading font-weight-bold",attrs:{"color":"primary","text-color":"white"}},[_vm._v("4")]),_c('span',{staticClass:"pl-2 title font-weight-bold"},[_vm._v("Do the job and earn")]),_c('p',{class:{
                  'px-5 ml-5 justifyText' : _vm.$vuetify.breakpoint.mdAndUp,
                  'pl-9 ml-1 justifyText' : _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v(" Once chosen, you may complete the task by giving the needed legal advice or drafting the document. After completing the task, the client will give a review on your work and you will earn your proposed fee. ")])],1)],1),_c('v-flex',{attrs:{"hidden-sm-and-down":"","xs6":"","mt-3":""}},[_c('v-img',{attrs:{"src":"https://res.cloudinary.com/starupgeek-law/image/upload/v1578038427/irdm56krlosckxdeatft.png"}})],1)],1)],1):_vm._e()])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }