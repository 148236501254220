<template>
  <v-app-bar
    app
    color="white"
    elevate-on-scroll
    height="60"
  >
    <v-row justify="center" align="center">
      <v-col cols="12" md="10" lg="10" xl="8" class="pa-0">
        <v-card-actions class="pa-0">
          <router-link to="/" class="pt-2">
            <img
              width="20%"
              src="https://res.cloudinary.com/starupgeek-law/image/upload/v1583571530/assets/txrdl3nqu81mbs7ykxyk.png"
            />
          </router-link>
          <v-spacer></v-spacer>
          <v-flex class="pr-3" xs2>
            <v-btn
              class="mr-2"
              color="primary"
              to="/login"
              block
              depressed
            >Sign In <v-icon right dark>mdi-key</v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs2>
            <v-menu
              offset-y
              transition="slide-y-transition"
            >
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                v-on="on"
                block
                text
              >Sign Up <v-icon right dark>mdi-menu-down</v-icon>
              </v-btn>
            </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in registerOptions"
                  :key="index"
                  @click="regOptions(index)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
          
          
        </v-card-actions>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Web-Index-Navbar',
  data () {
    return {
      options: [
        { title: 'Logout' }
      ],
      registerOptions: [
        { title: 'As Client' },
        { title: 'As Lawyer' }
      ]
    }
  },
  methods: {
    regOptions (index) {
      if (index === 0) {
        this.$store.commit('control/SET_REGISTER_TAB_INDEX', 0)
        this.$router.push('/register')
      } else {
        this.$store.commit('control/SET_REGISTER_TAB_INDEX', 1)
        this.$router.push('/register')
      }
    },
    loggedOut () {
      this.$store.dispatch('user/DELETE_USER_STATE')
      this.$store.dispatch('auth/DELETE_TOKEN')
        .then(res => {
          this.socket.emit('logout-lawyer', { email: this.$store.getters['user/user'].user.email })
          this.$router.push('/')
        })
    }
  },
  computed: {
    loggedIn () {
      return this.$store.getters['auth/loggedIn']
    },
    loggedInName () {
      let value = localStorage.getItem('loggedInName') || ''
      return value.substr(0, value.indexOf(' '))
    }
  }
}
</script>

<style>

</style>